html {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}
html, body, #root {
  height: 100%;
}

#root {
  display: flex;
  flex-direction: column;
 }
.homeurl:link { text-decoration: none; }


.homeurl:visited { text-decoration: none; }


.homeurl:hover { text-decoration: none; }


.homeurl:active { text-decoration: none; }